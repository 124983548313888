/* stylelint-disable */
@font-face {
  font-family: 'cd-icons';
  src:  url('cd-icons.eot');
  src:  url('cd-icons.eot#iefix') format('embedded-opentype'),
    url('cd-icons.ttf') format('truetype'),
    url('cd-icons.woff') format('woff'),
    url('cd-icons.svg#cd-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.cd {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cd-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tv:before {
  content: "\e900";
}
.icon-restaurant-manage:before {
  content: "\e901";
}
.icon-qr:before {
  content: "\e902";
}
.icon-mobile:before {
  content: "\e903";
}
.icon-dishes:before {
  content: "\e904";
}
.icon-cards:before {
  content: "\e905";
}
