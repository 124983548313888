/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';

/* stylelint-disable-next-line at-rule-empty-line-before */
@import '@components/jacidi-components-lib/styles/index';
@import './assets/fonts/crm-icons/style.css';
@import './assets/fonts/cd-icons/style.css';

html {
  font-size: 16px;
}

body {
  margin: 0;
}

.header-content {
  h3 {
    margin: 0;
  }
}

.p-dialog .p-dialog-footer button {
  margin: 0;
}

.p-tooltip-arrow {
  display: none;
}

/* Custom scrollbar styles */
*::-webkit-scrollbar {
  width: rem(9px);
  height: rem(9px);
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--surface-d);
  border-radius: rem(6px);
  border: rem(1px) solid transparent;
  background-clip: content-box;
}

*::-webkit-scrollbar-button,
*::-webkit-scrollbar-corner {
  display: none;
}

*::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:active {
  background-color: var(--text-primary-color);
}

// editor
.ql-formats:nth-last-child(2) {
  display: none;
}
