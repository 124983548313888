@import './functions/unit-transform';

.p-button.jacidi-button {
  background: var(--text-primary-color);
  border: rem(1px) solid var(--text-primary-color);

  &:not(:disabled) {
    &:hover {
      background: var(--hover-primary-btn);
      border-color: var(--hover-primary-btn);
    }

    &:active {
      background: var(--text-primary-color);
      border-color: var(--text-primary-color);
    }
  }
}

.p-button.jacidi-button-outlined {
  background: transparent;
  border: rem(1px) solid var(--text-primary-color);
  color: var(--text-primary-color);

  &:not(:disabled) {
    &:hover {
      background: transparent;
      border-color: var(--text-primary-color);
    }

    &:active {
      background: transparent;
      border-color: var(--text-primary-color);
    }
  }
}

.p-button.jacidi-button-text {
  background: transparent;
  border: rem(1px) solid transparent;
  color: var(--text-primary-color);

  &:not(:disabled) {
    &:hover {
      background: transparent;
      border-color: transparent;
    }

    &:active {
      background: transparent;
      border-color: transparent;
    }
  }
}

.p-button.jacidi-button:focus,
.p-button.jacidi-button-outlined:focus,
.p-button.jacidi-button-text:focus {
  box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
}

/* Dialog header button */
.p-dialog-header-icon:focus {
  box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
}

/* Select button */
.p-selectbutton.primary .p-button.p-highlight {
  background-color: var(--text-primary-color);
  border-color: var(--text-primary-color);
}

.p-selectbutton.primary .p-button.p-highlight:hover {
  background: var(--hover-primary-btn);
  border-color: var(--hover-primary-btn);
}

.p-selectbutton.primary .p-button:focus {
  box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
}

/* Checkbox */
.primary .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--text-primary-color);
}

.primary .p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--text-primary-color);
  background: var(--text-primary-color);
}

.primary .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
  border-color: var(--text-primary-color);
}

.primary.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--text-primary-color);
}

.primary.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--text-primary-color);
  background: var(--text-primary-color);
}

.primary.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
  border-color: var(--text-primary-color);
}
