@import './functions/unit-transform';

.p-tooltip {
  .p-tooltip-text {
    background: $white;
    color:#1e1f21;
    font-size: $font-size-12;
    letter-spacing: rem(0.1px);
    font-weight: $font-weight-semibold;
  }
}

.p-tooltip.p-tooltip-right {
  .p-tooltip-arrow {
    border-right-color: $white;
  }
}
