/* stylelint-disable */
@font-face {
  font-family: "crm-icons";
  src: url("crm-icons.eot");
  src: url("crm-icons.eot#iefix") format("embedded-opentype"),
    url("crm-icons.ttf") format("truetype"),
    url("crm-icons.woff") format("woff"),
    url("crm-icons.svg#crm-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.crm {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'crm-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.crm-add-circle:before {
  content: "\e900";
}
.crm-add-row:before {
  content: "\e901";
}
.crm-add-square:before {
  content: "\e902";
}
.crm-advanced-settings:before {
  content: "\e903";
}
.crm-advertisement:before {
  content: "\e904";
}
.crm-anticlock-wise:before {
  content: "\e905";
}
.crm-archive:before {
  content: "\e906";
}
.crm-area:before {
  content: "\e907";
}
.crm-arrow-firts:before {
  content: "\e908";
}
.crm-arrow-large-left:before {
  content: "\e909";
}
.crm-arrow-last:before {
  content: "\e90a";
}
.crm-arrow-left:before {
  content: "\e90b";
}
.crm-arrow-left-right-fill:before {
  content: "\e90c";
}
.crm-arrow-left-right-outline:before {
  content: "\e90d";
}
.crm-arrow-right:before {
  content: "\e90e";
}
.crm-arrow-right-1:before {
  content: "\e90f";
}
.crm-arrow-right-2:before {
  content: "\e910";
}
.crm-arrow-right--1:before {
  content: "\e911";
}
.crm-arrow-right--2:before {
  content: "\e912";
}
.crm-arrows-selector:before {
  content: "\e913";
}
.crm-arrow-up-down-fill:before {
  content: "\e914";
}
.crm-attachment:before {
  content: "\e915";
}
.crm-brign-top:before {
  content: "\e916";
}
.crm-brign-to-top:before {
  content: "\e917";
}
.crm-calendar:before {
  content: "\e918";
}
.crm-calendar-iconlinear:before {
  content: "\e919";
}
.crm-chart:before {
  content: "\e91a";
}
.crm-chart-1:before {
  content: "\e91b";
}
.crm-chart--1:before {
  content: "\e91c";
}
.crm-check-circle:before {
  content: "\e91d";
}
.crm-check:before {
  content: "\e91e";
}
.crm-check-list:before {
  content: "\e91f";
}
.crm-clock-wise:before {
  content: "\e920";
}
.crm-clockwise:before {
  content: "\e921";
}
.crm-close-line:before {
  content: "\e922";
}
.crm-cofing-alt:before {
  content: "\e923";
}
.crm-comment-outline:before {
  content: "\e924";
}
.crm-credit-card:before {
  content: "\e925";
}
.crm-crop:before {
  content: "\e926";
}
.crm-crown-circle:before {
  content: "\e927";
}
.crm-cursor:before {
  content: "\e928";
}
.crm-dashboard-linear:before {
  content: "\e929";
}
.crm-delete:before {
  content: "\e92a";
}
.crm-desktop-device:before {
  content: "\e92b";
}
.crm-device-mobile:before {
  content: "\e92c";
}
.crm-device-tablet:before {
  content: "\e92d";
}
.crm-dollar-sign:before {
  content: "\e92e";
}
.crm-dot-pattern:before {
  content: "\e92f";
  color: #b7bcc4;
}
.crm-download-linear:before {
  content: "\e930";
}
.crm-drag-arrow:before {
  content: "\e931";
}
.crm-drag-large:before {
  content: "\e932";
}
.crm-drag-short:before {
  content: "\e933";
}
.crm-dropdown-filled:before {
  content: "\e934";
}
.crm-duplicate:before {
  content: "\e935";
}
.crm-edit:before {
  content: "\e936";
}
.crm-error-warning-alt:before {
  content: "\e937";
}
.crm-error-warning:before {
  content: "\e938";
}
.crm-eye-hide:before {
  content: "\e939";
}
.crm-eye-show:before {
  content: "\e93a";
}
.crm-facebook:before {
  content: "\e93b";
}
.crm-featured:before {
  content: "\e93c";
}
.crm-file-edit-edit:before {
  content: "\e93d";
}
.crm-file-upload:before {
  content: "\e93e";
}
.crm-filters:before {
  content: "\e93f";
}
.crm-flow-chart-outline:before {
  content: "\e940";
}
.crm-folder-duplicate:before {
  content: "\e941";
}
.crm-folder:before {
  content: "\e942";
}
.crm-folder-move-to:before {
  content: "\e943";
}
.crm-folder-newicon:before {
  content: "\e944";
}
.crm-folder-open:before {
  content: "\e945";
}
.crm-forwap-clock:before {
  content: "\e946";
}
.crm-galery:before {
  content: "\e947";
}
.crm-galery-image-add:before {
  content: "\e948";
}
.crm-galery-video-add:before {
  content: "\e949";
}
.crm-globe:before {
  content: "\e94a";
}
.crm-heatmap:before {
  content: "\e94b";
}
.crm-image-placeholder:before {
  content: "\e94c";
}
.crm-info:before {
  content: "\e94d";
}
.crm-info-tooltip:before {
  content: "\e94e";
}
.crm-instagram:before {
  content: "\e94f";
}
.crm-instagram-1:before {
  content: "\e950";
}
.crm-instagram--1:before {
  content: "\e951";
}
.crm-interception:before {
  content: "\e952";
}
.crm-linkedin:before {
  content: "\e953";
}
.crm-link:before {
  content: "\e954";
}
.crm-list:before {
  content: "\e955";
}
.crm-location-outline:before {
  content: "\e956";
}
.crm-mail-linear:before {
  content: "\e957";
}
.crm-mail-linear .path1:before {
  content: "\e957";
  color: rgb(30, 31, 33);
}
.crm-mail-linear .path2:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(30, 31, 33);
  opacity: 0.0030;
}
.crm-map-pin-outline:before {
  content: "\e959";
}
.crm-marketing-campaings:before {
  content: "\e95a";
}
.crm-marketing-campaings-alt:before {
  content: "\e95b";
}
.crm-message-outline:before {
  content: "\e95c";
}
.crm-minus:before {
  content: "\e95d";
}
.crm-minus-zoom:before {
  content: "\e95e";
}
.crm-more-options:before {
  content: "\e95f";
}
.crm-more-zoom:before {
  content: "\e960";
}
.crm-multi-select:before {
  content: "\e961";
}
.crm-pages:before {
  content: "\e962";
}
.crm-performance:before {
  content: "\e963";
}
.crm-plus:before {
  content: "\e964";
}
.crm-property:before {
  content: "\e965";
}
.crm-rates-inventory:before {
  content: "\e966";
}
.crm-reset-zoom:before {
  content: "\e967";
}
.crm-resolve-comment:before {
  content: "\e968";
}
.crm-reverse-clock:before {
  content: "\e969";
}
.crm-rute:before {
  content: "\e96a";
}
.crm-scan:before {
  content: "\e96b";
}
.crm-search:before {
  content: "\e96c";
}
.crm-send-communication:before {
  content: "\e96d";
}
.crm-send-down:before {
  content: "\e96e";
}
.crm-send-down-1:before {
  content: "\e96f";
}
.crm-send-down--1:before {
  content: "\e970";
}
.crm-send-to-down--1:before {
  content: "\e971";
}
.crm-settings:before {
  content: "\e972";
}
.crm-snapchat-logo:before {
  content: "\e973";
}
.crm-sort-right:before {
  content: "\e974";
}
.crm-star-circle-fill:before {
  content: "\e975";
}
.crm-star-circle-outline:before {
  content: "\e976";
}
.crm-star-fill:before {
  content: "\e977";
}
.crm-star-outline:before {
  content: "\e978";
}
.crm-survey:before {
  content: "\e979";
}
.crm-system--1:before {
  content: "\e97a";
}
.crm-target:before {
  content: "\e97b";
}
.crm-tiktok:before {
  content: "\e97c";
}
.crm-time-clock-filled:before {
  content: "\e97d";
}
.crm-time-clock-outline:before {
  content: "\e97e";
}
.crm-title:before {
  content: "\e97f";
}
.crm-translate:before {
  content: "\e980";
}
.crm-twitter:before {
  content: "\e981";
}
.crm-undo:before {
  content: "\e982";
}
.crm-union:before {
  content: "\e983";
}
.crm-unlink:before {
  content: "\e984";
}
.crm-upload-linear:before {
  content: "\e985";
}
.crm-user-check-outline:before {
  content: "\e986";
}
.crm-user-details-outline:before {
  content: "\e987";
}
.crm-user-details-outline-1:before {
  content: "\e988";
}
.crm-user-disable-outline:before {
  content: "\e989";
}
.crm-user-group:before {
  content: "\e98a";
}
.crm-user-group-filled:before {
  content: "\e98b";
}
.crm-user-alternative:before {
  content: "\e98c";
}
.crm-user-manage:before {
  content: "\e98d";
}
.crm-user-merge-outline:before {
  content: "\e98e";
}
.crm-user-outline:before {
  content: "\e98f";
}
.crm-user-path-down:before {
  content: "\e990";
}
.crm-user-path-up:before {
  content: "\e991";
}
.crm-user-score:before {
  content: "\e992";
}
.crm-user-search:before {
  content: "\e993";
}
.crm-video:before {
  content: "\e994";
}
.crm-whatsapp:before {
  content: "\e995";
}
.crm-youtube:before {
  content: "\e996";
}
