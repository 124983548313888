/* stylelint-disable selector-not-notation */

@import './functions/unit-transform';
@import './variables';

.p-inputtext.primary:enabled:hover {
  border-color: var(--text-primary-color);
}

.p-inputtext.primary:enabled:focus {
  box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
  border-color: var(--text-primary-color);
}

.primary .p-inputtext:enabled:hover {
  border-color: var(--text-primary-color);
}

.primary .p-inputtext:enabled:focus {
  box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
  border-color: var(--text-primary-color);
}

.p-inputswitch.p-inputswitch-checked.primary .p-inputswitch-slider {
  background: var(--text-primary-color);
}

.p-inputswitch.p-focus.primary .p-inputswitch-slider {
  box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
}

/* Datepicker */
.primary.p-datepicker table td > span.p-highlight,
.primary.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: var(--text-primary-color);
  background: var(--shadow-light-color);
}

.primary.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
  border-color: var(--text-primary-color);
}

.primary.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus,
.primary.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus,
.primary.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
}

.primary.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.primary.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: var(--text-primary-color);
}
