:root {
  --sidebar-color: #1f1f1f;
  --alt-color-1: #5942f0;
  --text-primary-color: #5942f0;
  --text-secondary-color: #5942f0;
  --hover-color-1: #eeebff;
  --hover-primary-btn: #7663f1;
  --shadow-light-color: #e0daff;
  --border-primary-color: #5942f0;
  --paginator-btn-color: #1f1f1f;
}
