@import './functions/unit-transform';
@import './variables';
@import './z-index-hierarchy';

.fc {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 rem(18px);

  .fc-popover {
    z-index: $z-index-high;
  }

  .fc-list-empty {
    background-color: $white;
  }

  .fc-list-event-dot {
    display: none;
  }

  .fc-timegrid-col,
  .fc-daygrid-day {
    &.fc-day-today {
      background: var(--hover-color-1);
    }
  }

  .fc-timegrid-axis-cushion, .fc-timegrid-slot-label-cushion {
    color: var(--e-room-principal-medium, #334366);
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: $line-height-14;
  }


  .fc-toolbar-title {
    color: var(--e-room-principal-medium, #334366);
    text-align: center;
    font-size: $font-size-21;
    font-weight: $font-weight-semibold;
    line-height: $line-height-24;
  }

  .fc-toolbar.fc-header-toolbar {
    margin-top: rem(4px);
  }

  .fc-list-day-cushion {
    padding: rem(14px);
    color: var(--e-room-principal-medium, #334366);
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    line-height: $line-height-14;
  }

  .fc-col-header-cell-cushion {
    color: var(--e-room-principal-medium, #334366);
    font-size: $font-size-17;
    font-weight: $font-weight-semibold;
    line-height: $line-height-21;
    padding: rem(14px) 0;
  }

  td {
    vertical-align: middle;
  }
}

.fc-col-header {
  colgroup {
    border-bottom: rem(4px) solid var(--gray-stroke, #EBEFF2);
    border-left: rem(0px);
    border-right: rem(0px);
  }
}

.fc-direction-ltr .fc-timegrid-col-events {
  margin: rem(3px) 2.5% 0 rem(2px);
}

.fc-timegrid-axis {
  color:red;
}

.fc.fc-theme-standard {
  a {
    color: #334366;
  }

  .fc-view-harness .fc-cell-shaded {
    background: var(--gray-stroke, #ebeff2);
  }

  .fc-view-harness th {
    &.day-header-border {
      border-width: 0 0 rem(4px);
      border-color: var(--gray-stroke, #EBEFF2);
      border-style: solid;
    }

    &.text-align-start {
      text-align: start;
    }

    &.month-header-border {
      border-width: 0 rem(1px) rem(4px);
      border-color: var(--gray-stroke, #EBEFF2);
      border-style: solid;
    }

    background-color: $white;
    border-color: var(--white-white, #ebeff2);
    border-width: 0;
  }

  .fc-view-harness td {
    color: var(--e-room-principal-medium, #334366);
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: $line-height-14;
    border-color: #ebeff2;
  }

  .fc-toolbar {
    .fc-button {
      color: var(--gray-light-text, #53585e);
      text-align: right;
      font-size: $font-size-15;
      font-weight: $font-weight-regular;
      min-height: rem(32px);
      padding:rem(6px) rem(24px);
      background: var(--white-white, #fff);
      border: rem(2px) solid var(--gray-light-button, #E2E5EA);


      &:enabled {
        &:hover,
        &:active {
          background: var(--gray-light-button, #e2e5ea);
          color: var(--text-primary-color);
          border-color: var(--gray-light-button, #e2e5ea);
        }
      }

      &:disabled {
        color: var(--gray-light-text, #53585E);
        border-radius: rem(4px);
        border: rem(2px) solid var(--gray-light-button, #E2E5EA);
        background: var(--white-white, #FFF);
      }

      &.fc-next-button, &.fc-prev-button {
        padding: rem(4px) rem(8px);
      }

      /* stylelint-disable-next-line selector-class-pattern */
      &.fc-listWeek-button, &.fc-timeGridDay-button, &.fc-dayGridMonth-button, &.fc-timeGridWeek-button {
        padding: rem(8px) rem(24px);
        border: rem(2px) solid var(--gray-light-button, #e2e5ea);

        &.fc-button-active {
          background: var(--hover-color-1);
          color: var(--text-primary-color);
          border-color: var(--gray-light-button, #e2e5ea);

          &:hover,
          &:active {
            background: var(--gray-light-button, #e2e5ea);
            color: var(--text-primary-color);
            border-color: var(--gray-light-button, #e2e5ea);
          }
        }
      }
    }

    .fc-button-group .fc-button {
      &:first-child,
      &:last-child {
        border-top-left-radius: rem(4px);
        border-bottom-left-radius: rem(4px);
      }
    }
  }
}

.fc-theme-standard {
  .fc-list, .fc-scrollgrid {
    border: rem(4px) solid var(--gray-stroke, #EBEFF2);
    border-radius: rem(12px) rem(12px) 0 0;
  }

}

/* stylelint-disable-next-line selector-class-pattern */
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus, .fc.fc-theme-standard .fc-toolbar .fc-button:focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  box-shadow: 0 0 0 rem(4px) var(--shadow-light-color);
}
