@import './functions/unit-transform';
@import './variables';

.p-paginator.paginator {
  display: flex;
  padding: initial;

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last,
  .p-paginator-pages .p-paginator-page {
    min-width: initial;
    width: rem(24px);
    height: rem(24px);
    margin: 0 rem(4px);
    font-size: $font-size-14;
    color: var(--paginator-btn-color);
    background-color: #e9ecf0;
  }

  .p-paginator-page.p-highlight {
    background-color: var(--paginator-btn-color);
    color: $white;
  }

}

