@import './functions/unit-transform';
@import './variables';

.p-dropdown-label,
.p-dropdown-item {
  font-size: $font-size-14;
}

.p-dropdown.primary {
  &:not(.p-disabled) {
    &:hover {
      border-color: var(--hover-primary-btn);
    }

    &.p-focus {
      box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
      border-color: var(--hover-primary-btn);
    }

    .p-dropdown-item.p-highlight,
    .p-dropdown-item.p-highlight.p-focus {
      background: var(--hover-color-1);
      color: var(--text-primary-color);
    }
  }
}

.primary .p-dropdown-item.p-highlight,
.primary .p-dropdown-item.p-highlight.p-focus {
  background: var(--hover-color-1);
  color: var(--text-primary-color);
}

.p-multiselect.primary {
  &:not(.p-disabled) {
    &:hover {
      border-color: var(--hover-primary-btn);
    }

    &.p-focus {
      box-shadow: 0 0 0 rem(3px) var(--shadow-light-color);
      border-color: var(--hover-primary-btn);
    }
  }
}
