@import './functions/unit-transform';

/* FONT WEIGHTS */

$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

/* FONT SIZES */

$font-size-35: rem(35px);
$font-size-28: rem(28px);
$font-size-24: rem(24px);
$font-size-21: rem(21px);
$font-size-17: rem(17px);
$font-size-15: rem(15px);
$font-size-14: rem(14px);
$font-size-13: rem(13px);
$font-size-12: rem(12px);
$font-size-10: rem(10px);

/* LINE HEIGHTS */

$line-height-42: rem(42px);
$line-height-37: rem(37px);
$line-height-34: rem(34px);
$line-height-24: rem(24px);
$line-height-21: rem(21px);
$line-height-14: rem(14px);

/* COLORS */
$white: #fff;
