/*
Function to transform 'px' values to 'rem'.

Taken from https://medium.com/@bhargav3shah/scss-convert-pixel-values-to-rem-using-functions-f1cef575edfd
*/
@use 'sass:math';

// This should match the base font size set for the 'html' tag
// see src/styles.scss
$html-font-size: 16px;

@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@function rem($pxValue) {
  @return #{math.div(strip-unit($pxValue), strip-unit($html-font-size))}rem;
}
