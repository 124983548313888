@import './functions/unit-transform';
@import './variables';

.p-datatable {
  height: 100%;
  display: flex;
  flex-direction: column;

  .p-datatable-wrapper {
    height: 100%;
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: var(--text-primary-color);
    }
  }

  .p-datatable-thead,
  .p-datatable-tbody {
    tr {
      th,
      td {
        background: $white;
      }

      th:hover {
        background: $white;
        color: var(--text-primary-color);
      }

      th:focus {
        box-shadow: none;
      }
    }
  }

  .p-datatable-thead {
    tr {
      th {
        font-size: $font-size-12;
        text-transform: uppercase;
        color: var(--text-primary-color);
        font-weight: $font-weight-semibold;
        letter-spacing: rem(1.2px);
        white-space: nowrap;
      }
    }
  }

  .p-datatable-tbody {
    tr {
      height: 40px; // esto para el 50%

      td {
        font-size: $font-size-12;
        color: var(--text-color);
        background: transparent;
        padding: 0 rem(16px); // esto para el 50%
        white-space: nowrap;

        span {
          cursor: pointer;
        }
      }
    }

    tr.p-highlight {
      background: var(--hover-color-1) !important;
    }

    tr:focus {
      outline: none;
      outline-offset: 0;
    }

    tr:last-child td {
      border: none;
    }
  }
}
